.header {
    display: flex;
    align-items: center;
    height: 50px;
    background: #fff;
    width: 100%;
    padding: 0 50px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    position: fixed;
    z-index: 999;
    justify-content: space-between;
    font-size: 13px;
}
.header h5 {
    margin: 10px 20px 0;
}
.header > div {
    display: flex;
}