.Button {
    min-width: 150px;
    border: 2px solid #888;
    border-radius: 4px;
    background: #fff;
    padding: 4px;
    color: #555;
    margin: 5px;
}

.Button:hover {
    background: #f8f8f8;
}