/* loader */
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lds-dual-ring {
  display: block;
  width: 64px;
  height: 64px;
  position: relative;
  left: 47%;
  top: 40px;
  z-index: 1;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid;
  border-color: #ccc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.font9 {
  font-size: 9px;
}

.text-actual {
  color: #232323;
  font-weight: 700;
}

.text-prev {
  font-size: 10px;
  color: #9d9d9d;
}

.text-red {
  color: #fe0000;
}

.text-green {
  color: #21b634;
}

html,
body {
  height: 100%;
  min-height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 13px;
  background-color: #eee;
  color: #646464;
}

.container-fluid {
  max-width: 1280px;
  margin: auto;
}

.content-holder {
  margin-top: 60px;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19);
}

.content-holder .content-header {
  background: url(./assets/img/bg.jpg) no-repeat center/cover;
  color: #fff;
  position: relative;
}

.content-header h1 {
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 700;
  font-size: 2.8rem;
}

.content-header h6 {
  letter-spacing: 3px;
  margin: 0;
  padding: 20px 0 18px 0;
  font-size: 0.8rem;
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 600;
}

.content-header .content-filters {
  background-color: rgba(0, 0, 0, 0.4);
}

.content-header .headroom--unpinned {
  background: #000;
}

.content-header .content-filters span {
  color: #fff;
  font-size: 11px;
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 300;
  padding-right: 10px;
}

.content-filters .DateInput {
  background: transparent !important;
}

.content-filters .DateInput__small {
  width: 80px;
}

.content-filters .DateRangePickerInput {
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-filters .DateRangePickerInput_arrow {
  color: #fff !important;
}

.content-filters .DateInput input:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.content-filters .DateInput input {
  font-size: 11px;
  line-height: 13px;
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 700;
  background: transparent;
  color: #fff;
}

.content-filters .DateInput__small {
  width: 80px !important;
}

.content-filters .DateRangePickerInput_calendarIcon_svg {
  fill: #d7d7d7;
}

.content-filters .DateRangePickerInput_calendarIcon {
  margin: 0;
  vertical-align: middle;
}

.content-filters .DateInput input:hover {
  background-color: #eaeaea;
  cursor: pointer;
}

.content-filters .DateInput input {
  padding: 2px 9px 2px;
}

.content-filters .DateRangePickerInput_calendarIcon {
  padding: 3px !important;
}

.content-header .content-filters label {
  margin-bottom: 0;
  padding-right: 10px;
}

.content-header .content-filters select {
  padding: 2px 5px;
  border-color: #9e9e9e;
  border-radius: 40px;
  width: 150px;
  margin: 0;
}

.content-menu {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #dedede;
  text-align: center;
}

.content-menu a {
  font-size: 12px;
  color: #999;
  border-bottom: 2px solid transparent;
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 300;
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 15px;
}

.content-menu a:hover {
  color: red;
  border-color: red;
  font-weight: 700;
  text-decoration: none;
}

.content-menu .btn-back-top {
  border: 1px solid #9e9e9e;
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #eaeaea !important;
  box-shadow: 1px 1px 4px 0px rgba(158, 158, 158, 1);
}

.summary-section {
  background: #f0f0f0;
}

.summary-section h4 {
  color: red;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 700;
  text-align: center;
  margin-bottom: 7px;
}

.summary-section p.semi-title {
  font-family: Montserrat, Arial, sans-serif;
  font-weight: 400;
  text-align: center;
  margin: 0;
}

.summary-section .subtitle {
  text-align: center;
  color: #999;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 300;
}

.summary-section .subtitle h6 {
  font-size: 16px;
}

.summary-section .subtitle p {
  margin: 0;
}

.tooltip2 h5:after,
.quotation-mark {
  background-image: url(./assets/img/question-circle-solid.svg);
  background-size: 10px 20px;
  display: inline-block;
  width: 10px;
  height: 20px;
  margin-left: 3px;
  content: "";
}

.tooltip2 .tooltiptext {
  display: none;
  width: 240px;
  background-color: #222;
  color: #fff;
  text-align: left;
  padding: 8px;
  border-radius: 5px;
  position: absolute;
  z-index: 999;
  bottom: 10px;
  left: 50%;
  margin-left: -120px;
  font-size: 11px;
  font-weight: 300;
  text-transform: none;
}

.tooltip2 .tooltiptext:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #222 transparent transparent transparent;
}

.tooltip2:hover .tooltiptext {
  display: block;
}

.quotation-mark {
  background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" class="svg-inline--fa fa-question-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="red" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"></path></svg>');
}

.table-responsive.with-padd {
  padding-right: 12px;
}

table.query-days {
  margin-bottom: 0;
}

table.query-days tr th {
  border-top: 0 none;
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 700;
  font-size: 11px;
  vertical-align: bottom;
  padding: 0 0 10px 0;
  text-align: right;
}

table.query-days tr th:first-child {
  text-align: left;
}

table.query-days tr th div {
  width: 20px;
  height: 40px;
  float: right;
  overflow: hidden;
}

table.query-days tr th span {
  display: block;
  width: 100%;
  position: absolute;
  transform: rotate(270deg);
  bottom: 5px;
  right: -3px;
  white-space: nowrap;
}

table.query-days tr td {
  font-family: "Roboto", Arial, sans-serif;
  font-weight: 300;
  padding: 2px;
  font-size: 12px;
  vertical-align: middle;
  text-align: right;
}

table.query-days tr td:first-child {
  width: 250px;
  text-align: left;
}

table.query-days tbody tr:last-child td {
  border-bottom: 1px solid #dee2e6;
}

table.query-days tr td input {
  vertical-align: middle;
}

.table-summary-competition {
  table-layout: fixed;
}

.table-summary-competition thead tr th {
  border: 0 none;
  border-bottom: 2px solid #dee2e6;
  color: #fe0000;
  font-weight: 700;
  padding: 0 0.75rem 0.5rem 0.75rem;
}

.table-summary-competition thead tr th:first-child {
  border-bottom: 0 none;
}

.table-summary-competition tbody td {
  vertical-align: middle;
  border: 0 none;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding: 0.5rem 0.75rem;
}

.table-summary-competition tbody td:last-child {
  border-right: 0 none;
}

.table-summary-competition tbody tr:last-child td {
  border-bottom: 0 none;
}

.table-legend-competition {
  table-layout: fixed;
  margin-bottom: 0;
}

.table-legend-competition thead tr th {
  border-top: 0 none;
  text-align: center;
  text-transform: uppercase;
  color: #242424;
  padding-top: 0;
}

.table-legend-competition tbody tr td {
  border-right: 1px solid #dee2e6;
  border-top: 0 none;
  padding: 0.5rem 0.75rem 3.4rem 0.75rem;
}

.table-legend-competition tbody tr td:last-child {
  border-right: 0 none;
}

.table-legend-competition tbody tr td.pb-05 {
  padding-bottom: 0.5rem;
}

.legend-point {
  position: relative;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
  transform: scale(1);
  color: #555;
}

.legend-point:after {
  content: "";
  display: none;
  width: 100%;
  background-color: #000;
  height: 1px;
  position: absolute;
  top: 10px;
}

.legend-point.stroke:after {
  display: block;
}

.legend-point:hover {
  opacity: 0.6;
}

.legend-point i.legend-ico {
  display: inline-block;
  vertical-align: middle;
  padding: 5px 9px;
  border: 3px solid transparent;
  margin-right: 8px;
  background-color: #fff;
  transition: all 0.3s;
}

.legend-point:hover i.legend-ico {
  border-color: #000;
}

.summary-table {
  text-align: center;
}

.summary-table thead tr th {
  color: #fe0000;
}

.summary-table td .sum {
  color: #232323;
  font-weight: 700;
  margin: 0;
  font-family: Roboto, Arial, sans-serif;
  font-size: 1.5rem;
  padding-left: 20px;
}

.summary-table td .diff {
  color: #999;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 300;
  font-size: 0.9rem;
  position: absolute;
  right: 0;
  text-align: right;
}

.summary-table td .diff .text-prev {
  font-size: 0.85rem;
}

@page {
  size: A4 landscape;
  margin: 0.5cm;
}

@media print {
  body {
    background-color: #fff;
  }

  .print-page-break-before {
    page-break-before: always;
  }

  .chartjs-size-monitor {
    display: none;
  }

  .header *,
  .content-header *,
  .components-content-holder h5.mb-5,
  .table.query-days {
    color: #000 !important;
  }

  .container-fluid {
    max-width: 100%;
    margin: auto;
  }

  .header {
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0;
    height: auto;
    position: static;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .header h5 {
    margin-right: 0;
  }

  .container {
    max-width: 100% !important;
    width: 100% !important;
    margin-top: 0.75rem;
  }

  .content-holder {
    margin: 0;
  }

  .content-holder .content-header {
    background-image: none;
    background-color: #fff;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content-header h1 {
    font-size: 2rem;
  }

  .content-holder .content-header p span {
    display: inline-block;
    vertical-align: middle;
    width: 115px;
  }

  .content-holder .content-header p span svg {
    margin-right: 5px;
  }

  .components-content-holder .pt-4,
  .components-content-holder .px-4,
  .components-content-holder .pt-5,
  .components-content-holder .pb-4,
  .components-content-holder .mb-5 {
    margin: 0 !important;
    padding: 0 !important;
  }

  .components-content-holder > div {
    margin-top: 3rem !important;
    margin-bottom: 0 !important;
  }

  .components-content-holder div[name="href2"] {
    margin-top: 1.5rem !important;
  }

  .summary-section {
    background-color: #fff;
    padding: 0 !important;
  }

  .summary-section .tooltip2 .position-relative {
    display: none;
  }

  .components-content-holder h5.mb-5 {
    text-align: left !important;
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }

  .summary-section h5:after {
    display: none;
  }

  table.query-days tr th:nth-child(2) div,
  table.query-days tr td:nth-child(2) input {
    display: none;
  }

  table.query-days tr td:first-child {
    width: 200px;
  }

  table.query-days.phase {
    margin-top: 3rem;
  }

  table.query-days.phase tr td:not(:first-child) {
    text-align: right !important;
    width: 40px;
  }

  .legend-point.stroke:after {
    display: none;
  }

  .legend-point.stroke .stroke-line {
    text-decoration: line-through;
  }
}

.noEvents .dots-container {
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  position: fixed;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px 10px 30px 10px;
  font-size: 12px;
  box-shadow: 0px 0px 20px 0px rgba(66, 68, 90, 0.5);
}

.noEvents .dots-container h6 {
  color: #da2929;
  margin: 0;
}

.noEvents .dots,
.noEvents .dots:before,
.noEvents .dots:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.noEvents .dots {
  color: #da2929;
  font-size: 0.5rem;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  margin: auto;
}

.noEvents .dots:before,
.noEvents .dots:after {
  content: "";
  position: absolute;
  top: 0;
}

.noEvents .dots:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.noEvents .dots:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -0.9em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -0.9em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

canvas {
  width: 100% !important;
}
