.input {
    border: 1px solid rgba(0,0,0,0.1);
    background: #fff;
    padding: 3px 7px;
    font-size: inherit;
    font-family: Roboto, Arial, sans-serif;
    border-radius: 1px;
    font-weight: normal;
    outline-width: 0;
}

.input::placeholder {
    color: #c7c7c7;
}
