.ReactTable .rt-td {
    padding: 2px 5px;
}
.tbl-header {
    color: #555;
    font-family: "Roboto", Arial, sans-serif;
    font-weight: 700;
}
.rt-td {
    font-family: "Roboto", Arial, sans-serif;
    font-weight: 300;
}
.ico-sort {
    width: 7px;
    margin: -2px 5px 0;
    filter: invert(.2);
}
